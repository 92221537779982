import React, { useEffect, useState } from 'react';
import './UnitModal.css';

const UnitModal = ({ unit, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (unit) {
            setIsVisible(true); // Ativa a animação de entrada
        } else {
            setTimeout(() => setIsVisible(false), 300); // Aguarda a animação de saída antes de remover o modal
        }
    }, [unit]);

    if (!unit && !isVisible) return null;

    return (
        <div
            className={`unit-modal ${unit ? 'slide-in' : ''}`}
            onClick={onClose}
        >
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>×</button>
                <h2 className="modal-title">{unit?.title}</h2>
                {/*
                <img src={unit?.image} alt={unit?.title} className="modal-image" />
                */}
                <p
  className="modal-description"
  dangerouslySetInnerHTML={{
    __html: unit?.description.replace(/\n/g, '<br />')
  }}
/>
            </div>
        </div>
    );
};

export default UnitModal;
