// data/unitsData.js
const unitsData = [
  {
    id: 'santuario',
    title: 'Complexo Santuário Santa Dulce dos Pobres',
    image: '/assets/unid/santuario.jpg',
    description: 'O Complexo Santuário é composto pelo Santuário Arquidiocesano de Santa Dulce dos Pobres, Memorial Santa Dulce dos Pobres, Loja Santa Dulce, Dulce Café e o Núcleo de Turismo Religioso. Todos estes espaços voltados para multiplicar a devoção e a espiritualidade da primeira santa brasileira.',
  },
  {
    id: 'galinheiro',
    title: 'Galinheiro',
    image: '/assets/unid/galinheiro.jpg',
    description: 'Marco zero da instituição, o galinheiro (espaço simbólico), fica ao lado do Convento Santo Antônio, onde, em 1949, Santa Dulce dos Pobres passou a acolher pobres e doentes em situação de vulnerabilidade social.',
  },
  {
    id: 'crianca',
    title: 'Hospital da Criança',
    image: '/assets/unid/crianca.jpg',
    description: 'Inaugurado em dezembro de 2000, com 102 leitos, o Hospital da Criança oferece assistência médico-hospitalar humanizada. Foi o primeiro hospital na Bahia a atender a todas as exigências do Conanda – Conselho Nacional de Defesa dos Direitos da Criança e do Adolescente – quanto aos direitos dos pacientes hospitalizados, entre eles, o direito de ter a mãe como acompanhante durante todo o período de internação. O Hospital da Criança também possui programas socioeducativos, como o Escola no Hospital, o Combate aos Maus Tratos e o Clube da Família.',
  },
  {
    id: 'reabilitacao',
    title: 'Centro de Reabilitação de Anomalias Crânio Faciais - CENTRINHO',
    image: '/assets/unid/crianca.jpg',
    description: 'Inaugurado em 1998, o Centrinho, é a segunda maior unidade do país na recuperação de fissurados e referência para o Ministério da Saúde no atendimento a pacientes dos estados do Norte e Nordeste. O núcleo atende os portadores das chamadas anomalias labiopalatais, como o lábio leporino e a “goela de lobo”. O atendimento multidisciplinar do Centrinho investe na recuperação integral e na elevação da autoestima do público assistido.',
  },
  {
    id: 'antonio',
    title: 'Hospital Santo Antônio',
    image: '/assets/unid/antonio.jpg',
    description: 'As Obras Sociais Irmã Dulce são comumente identificadas pela população com Hospital Santo Antônio ou Hospital de Irmã Dulce, sendo uma das maiores unidades de saúde do Norte e Nordeste do país, das mais bem equipadas da Bahia e registra hoje uma média de 16,5 mil internações e 14.400 cirurgias anuais. Atualmente com 720 leitos e um Centro de Tratamento Intensivo com 30 leitos.',
  },
  {
    id: 'sarney',
    title: 'Ambulatório José Sarney',
    image: '/assets/unid/sarney.jpg',
    description: 'Principal porta de entrada das Obras Sociais Irmã Dulce, o Ambulatório José Sarney, inaugurado em 1986, realiza mais de 540 mil procedimentos por ano a pacientes da capital e do interior. São 40 especialidades médicas oferecidas.',
  },
  {
    id: 'magalhaes',
    title: 'Centro de Geriatria e Gerontologia Júlia Magalhães',
    image: '/assets/unid/magalhaes.jpg',
    description: 'Referência no atendimento ao idoso, o Centro de Geriatria e Gerontologia, criado em 1986, é o único complexo do estado a oferecer, simultaneamente, atendimento ambulatorial e internação hospitalar para casos graves e pacientes crônicos. São 18 leitos para patologias agudas, 12 leitos para cuidados paliativos, 12 para reabilitação, 54 para patologias crônicas e 54 para longa permanência. O Centro mantém uma morada para idosos em situação de vulnerabilidade, com 54 leitos.',
  },
  {
    id: 'unacon',
    title: 'Unidade de Alta Complexidade em Oncologia N. Sra. de Fátima',
    image: '/assets/unid/unacon.jpg',
    description: 'Inaugurada em 13 de maio de 2015, a Unidade de Alta Complexidade em Oncologia N. Sra. de Fátima disponibiliza tratamento completo contra o câncer, incluindo procedimentos de radioterapia e quimioterapia, consultas ambulatoriais, internamento e cirurgias no campo oncológico. O complexo conta com uma estrutura que compreende 1.700 metros quadrados de área construída, 12 consultórios, 18 poltronas para quimioterapia, 5 leitos para intercorrências e modernos aparelhos para o atendimento a cerca de 4,8 mil pacientes por mês.',
  },
  {
    id: 'capd',
    title: 'Centro de Acolhimento à Pessoa com Deficiência João Paulo II – CAPD',
    image: '/assets/unid/capd.jpg',
    description: 'O Centro de Acolhimento à Pessoa com Deficiência presta assistência integral a indivíduos com múltiplas deficiências. A maioria deles foi, há muitos anos, carinhosamente acolhida nas dependências da OSID pelo próprio Anjo Bom. Atualmente, 88 pessoas, de ambos os sexos, têm no CAPD a sua morada, sendo que menos de 40% possuem alguma referência familiar.',
  },
  {
    id: 'cata',
    title: 'Centro de Acolhimento e Tratamento de Alcoolistas (CATA) e Centro Médico Social Augusto Lopes Pontes (CMSALP)',
    image: '/assets/unid/cata.jpg',
    description: '<b>O CATA:</b></br>Fundado em dezembro de 1994, o Centro de Acolhimento e Tratamento de Alcoolistas é a única unidade de saúde da Bahia dedicada exclusivamente ao atendimento de pessoas com problemas relacionados ao uso abusivo e à dependência de etílicos, pelo Sistema Único de Saúde, oferecendo tanto atendimento ambulatorial quanto internação.</br></br><b>O CMSALP</b></br> Destinado a pacientes crônicos que necessitam de internação de longa permanência, o Centro Médico Social Augusto Lopes Pontes proporciona assistência médica integral por meio de uma equipe multidisciplinar. Conta, ainda, com o apoio de pedagogas que desenvolvem trabalhos de alfabetização de adultos e promovem a reintegração desses pacientes ao ensino regular, preparando-os para retomar seus estudos no momento da alta hospitalar.',
  },
    {
      id: 'upa',
      title: 'UPA Santo Antonio',
      image: '/assets/unid/upa.jpg',
      description: 'A UPA Santo Antônio disponibiliza atendimento de urgência e emergência em regime de 24 horas, contando com uma base do Samu (Serviço de Atendimento Móvel de Urgência) e com serviço de atenção domiciliar.',
    },
    {
      id: 'dulce',
      title: 'Unidade Dona Dulce – UDD',
      image: '/assets/unid/osid_udd.jpg',
      description: 'Unidade de saúde voltada ao acolhimento dos pacientes oncológicos em tratamento na instituição. Batizada em homenagem à mãe de Santa Dulce, a Unidade Dona Dulce é a tradução fiel da solidariedade, e tem como padrinho, entre os seus doadores, o querido e saudoso ator Paulo Gustavo.',
    },
    
    // Adicione outras unidades aqui
  ];
  
  export default unitsData;
  