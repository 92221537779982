import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'; // Importação do Leaflet
import bahiaData from './bahia.json'; // Assumindo que você já tem o GeoJSON correto da Bahia
import './Map.css';

// Coordenadas e informações dos hospitais
const hospitals = [
  {
    name: "Complexo Roma – Sede Principal",
    shortName: "Sede",
    location: [-12.936, -38.511],
    city: "Salvador",
    since: 1959,
    offset: [0.7, 0.4],
    description: "Localizado em Salvador, na Cidade Baixa, o Complexo Roma possui 40 mil metros quadrados de área construída, onde se concentram 20 dos 21 núcleos de atendimentos da instituição, dentre eles o Hospital Santo Antônio, Centro Geriátrico, Hospital da Criança, Unidade de Alta Complexidade em Oncologia, Centro de Acolhimento à Pessoa com Deficiência, o Centro Médico Social Augusto Lopes Pontes o Centro de Acolhimento e Tratamento de Alcoolistas. Possui um total de 720 leitos hospitalares. Como hospital-escola, a OSID oferece internato de Medicina e 19 programas em residências médicas, além da Residência Multiprofissional em Atenção à Saúde do Idoso e Residência em Odontologia.",
    photo: "/img/img01.jpg"
  },
  {
    name: "Centro Educacional Santo Antônio (CESA)",
    shortName: "C. Educacional",
    location: [-12.791, -38.403],
    city: "Simões Filho",
    since: 1964,
    offset: [0.2, 0.8],
    description: "Localizada no município de Simões Filho, Região Metropolitana de Salvador, a unidade, fundada em 1964 por Santa Dulce, nasceu como um orfanato que abrigava meninos sem referência familiar. Em 1994, tornou-se uma escola em tempo integral, sendo hoje uma referência na adoção de boas práticas de gestão alinhadas com princípios de excelência na área educacional. O CESA atende mais de 900 crianças e adolescentes em situação de vulnerabilidade social. Possui um modelo de ensino que engloba do primeiro ao nono ano (Ensino Fundamental I e II), e oferece também acesso à arte-educação, iniciação profissional, atividades esportivas, assistência odontológica, alimentação, fardamento e material escolar gratuitos. O complexo dispõe de uma unidade de sustentabilidade, o Centro de Panificação, que produz e comercializa variados tipos de pães, panetones, broas, cookies e brownies, cuja receita das vendas é totalmente revertida para a manutenção de suas atividades. É também onde está localizado o Convento do Instituto Filhas de Maria Servas dos Pobres.",
    photo: "/img/img02.jpg"
  },
  {
    name: "Hospital do Oeste (HO)",
    shortName: "H. do Oeste",
    location: [-12.145, -44.998],
    city: "Barreiras",
    since: 2006,
    offset: [0, 0],
    description: "Primeiro hospital público estadual assumido pela OSID na modalidade de Organização Social (OS). Atende toda a região do oeste da Bahia e alguns municípios de outros estados.",
    photo: "/img/img03.jpg"
  },
  {
    name: "Hospital Eurídice Sant’Anna",
    shortName: "H. Eurídice",
    location: [-11.011, -44.526],
    city: "Santa Rita de Cássia",
    since: 2008,
    offset: [0, 0],
    description: "O Hospital Eurídice Sant’Anna também presta assistência à população das cidades de Formosa do Rio Preto, Riachão das Neves e Mansidão, uma das regiões mais carentes da Bahia, além de atender pacientes do sul do Piauí.",
    photo: "/img/img04.jpg"
  },
  {
    name: "Hospital Regional Dr. Mário Dourado Sobrinho",
    shortName: "H. Irecê",
    location: [-11.305, -41.855],
    city: "Irecê",
    since: 2012,
    offset: [0, 0],
    description: "Situado no centro de Irecê, a cerca de 470 Km de Salvador, a unidade, vinculada ao governo do estado, é referência em serviços de saúde de média e alta complexidade para mais de 700 mil habitantes de 38 municípios.",
    photo: "/img/img05.jpg"
  },
  {
    name: "Hospital Regional de Juazeiro",
    shortName: "H. Juazeiro",
    location: [-9.416, -40.505],
    city: "Juazeiro",
    since: 2020,
    offset: [0, 0],
    description: "O Hospital Regional de Juazeiro conta com diversas especialidades, além de uma Unidade de Alta Complexidade em Oncologia.",
    photo: "/img/h_juazeiro.jpeg"
  },
  {
    name: "Hospital Regional de Paulo Afonso",
    shortName: "H. Paulo Afonso",
    location: [-9.406, -38.215],
    city: "Paulo Afonso",
    since: 2022,
    offset: [0, 0],
    description: "A unidade dispõe de 60 leitos, sendo 50 de Clínica Médica e Cirúrgica e 10 leitos de Unidade de Terapia Intensiva (UTI).",
    photo: "/img/h_paulo_afonso.jpeg"
  },
  {
    name: "Centro de Convivência Irmã Dulce dos Pobres (CCIDP)",
    shortName: "CCIDP",
    location: [-12.936, -38.511],
    city: "Salvador",
    since: 2013,
    offset: [0.6, -0.8],
    description: "Localizado no Centro Histórico de Salvador, o Centro de Convivência Irmã Dulce dos Pobres foi idealizado pelas Obras Sociais Irmã Dulce (OSID), em parceria com a Secretaria Estadual da Saúde (SESAB). A unidade presta assistência às pessoas em sofrimento psíquico e em vulnerabilidade social pertencentes ao território da Península Itapagipana e Centro Histórico, especialmente aos usuários de substâncias psicoativas, além do atendimento às famílias residentes no bairro e clientes referenciados pelo Sistema Único de Saúde. As atividades do núcleo têm como base o resgate e fortalecimento da cidadania; promoção de saúde e prevenção de doenças; incentivo à arte, cultura e educação; redução de riscos e danos do uso e abuso de substâncias psicoativas e doenças sexualmente transmissíveis; empoderamento social e a economia solidária associada à geração de renda.",
    photo: "/img/img06.jpg"
  },
  {
    name: "UPA Santo Antônio",
    shortName: "UPA St Antônio",
    location: [-12.948, -38.464],
    city: "Salvador",
    since: 2021,
    offset: [0.1, -0.5],
    description: "A unidade presta atendimento de urgência e emergência em regime de 24 horas e dispõe de uma base do Samu (Serviço de Atendimento Móvel de Urgência) e Serviço de Atenção Domiciliar.",
    photo: "/img/upa_santo_antonio.jpeg"
  },
  {
    name: "Unidade Patamares",
    shortName: "Patamares",
    location: [-12.948, -38.399],
    city: "Salvador",
    since: 2021,
    offset: [-0.2, 1],
    description: "Prédio doado pela família do Sr. Edilson Celestino da Silva, é composto por quatro pavimentos e dois anexos, construído em um terreno de mais de 12 mil metros quadrados, localizado na Avenida Tamburugy, no bairro de Patamares. Neste local funcionam atualmente, o ambulatório do Centro Especializado em Reabilitação - CER IV, com atuação nas temáticas auditiva, física, intelectual e visual, o Centro de Pesquisa Clínica (CPEC) e alguns núcleos de apoio administrativo da instituição. Onde funcionará a Faculdade Santa Dulce, a partir de 2026.",
    photo: "/img/unid_patamares.jpeg"
  },
  {
    name: "Hospital Municipal do Homem",
    shortName: "H. do Homem",
    location: [-12.973, -38.502],
    city: "Salvador",
    since: 2022,
    offset: [-0.4, 0],
    description: "Instalado no antigo Hospital da Sagrada Família, no bairro Mont Serrat, a unidade é equipada com 140 leitos clínico-cirúrgicos, sendo 10 destinados à UTI e 10 ao Hospital Dia. É referência em tratamentos urológicos e vasculares, com equipamentos de última geração que permitem a realização de cirurgias de próstata e cálculos renais de forma menos invasiva.",
    photo: "/img/h_homem.jpeg"
  },
  {
    name: "Hospital Estadual Mont Serrat – Cuidados Paliativos",
    shortName: "H. Mont Serrat",
    location: [-12.990, -38.512],
    city: "Salvador",
    since: 2025,
    offset: [-0.8, 0.4],
    description: "O primeiro hospital público do Brasil dedicado exclusivamente a essa abordagem essencial no cuidado à vida. A unidade conta com 70 leitos clínicos, serviços especializados como terapia da dor e apoio ao luto, além de suporte para ensino e pesquisa. Mais do que um avanço na saúde pública, esse hospital representa a essência do legado de Santa Dulce dos Pobres: cuidar com amor e compaixão.",
    photo: "/img/h_mont.jpg"
  }
];


// Cores fixas para os marcadores
const markerColors = ['#1E90FF', '#4682B4', '#2F4F4F', '#808080', '#DAA520', '#8B4513'];

// Função para criar ícones de marcadores personalizados
const createCustomIcon = (shortName, color) => {
  return new L.DivIcon({
    className: 'custom-div-icon',
    html: `
      <div style="background-color:${color}; color:white; padding:8px; border-radius:5px;
                  min-width:100px; text-align:center; box-shadow:2px 2px 5px rgba(0, 0, 0, 0.5);">
        ${shortName}
      </div>`,
    iconSize: [120, 42],
    iconAnchor: [60, 42]
  });
};

// Estilo para a linha da Bahia e os municípios
const bahiaStyle = {
  color: '#808080', // Linha cinza sólida
  weight: 1,
  dashArray: '', // Remover o pontilhado
  fillOpacity: 0
};

// Estilo para os municípios destacados
const highlightedStyle = {
  fillColor: 'orange', // Amarelo alaranjado
  color: '#808080', // Cor da borda cinza
  fillOpacity: 0.7
};

// Função para definir o estilo dos municípios com hospitais
const getFeatureStyle = (feature) => {
  const municipiosComHospitais = [
    "Salvador", "Barreiras", "Santa Rita de Cássia", "Irecê", "Juazeiro", "Paulo Afonso"
  ];

  if (municipiosComHospitais.includes(feature.properties.name)) {
    return highlightedStyle;
  }

  return bahiaStyle;
};

const Maps = () => {
  
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <MapContainer
        center={[-12.97, -38.51]}
        zoom={7}
        minZoom={6}
        maxZoom={10}
        style={{ height: "100%", width: "100%" }}
      >
        {/* Mapa com estilo simplificado */}
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Exibir o GeoJSON da Bahia com destaque para os municípios */}
        <GeoJSON data={bahiaData} style={getFeatureStyle} />

        {/* Adicionar marcadores personalizados com offset */}
        {hospitals.map((hospital, index) => {
          const markerPosition = [
            hospital.location[0] + hospital.offset[0],
            hospital.location[1] + hospital.offset[1]
          ];
          const lineCoordinates = [hospital.location, markerPosition]; // Linha entre localização original e offset

          return (
            <React.Fragment key={index}>
              {/* Linha entre a posição original e a nova posição com offset */}
              <Polyline positions={lineCoordinates} color="black" weight={1} />
              <Marker
                position={markerPosition}
                icon={createCustomIcon(hospital.shortName, markerColors[index % markerColors.length])}
              >
                <Popup offset={[250, 310]} autoPan={true}>
                  <strong>{hospital.name}</strong><br />
                  Cidade: {hospital.city}<br />
                  Desde: {hospital.since}<br />
                  <p>{hospital.description}</p>
                  <img src={hospital.photo} alt={hospital.name} style={{ width: '100%', height: 'auto' }} />
                </Popup>
              </Marker>
            </React.Fragment>
          );
        })}
      </MapContainer>
    </div>
  );    
};



export default Maps;
